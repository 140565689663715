/* eslint-disable no-unused-vars */
import { Editor } from "@tinymce/tinymce-react";
import { Button, Input, Spin, Tree, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CourseContainer from "../CourseContainer";
import SubSectionContainer from "../SubSectionContainer";
import {
  DeleteOutlined,
  DownOutlined,
  LoadingOutlined,
  SmileOutlined,
  UpOutlined,
} from "@ant-design/icons";
import SisLoader from "../../../../widgets/loader";
import {
  ADD_SECTION,
  DELETE_SECTION,
  SUBSECTIONS_LIST,
  UPDATE_SECTION,
  UPDATE_SECTION3,
} from "../../../../apis/apis";
import AddSubSection from "./AddSubSection";
import EditSubSection from "./EditSubSection";
import { TINY_KEY } from "../../../../config/config";

const AddSection = (props) => {
  const {
    programme_id,
    set_open_section,
    set_page_loader,
    set_add_section_form,
    set_add_section_button,
  } = props;

  const editorRef = useRef(null);

  const [sub_page_loader, set_sub_page_loader] = useState(true);
  const [sub_section, set_sub_section] = useState([]);
  const [open_sub_section, set_open_sub_section] = useState(0);
  const [show_form, set_show_form] = useState(true);

  const [title, set_title] = useState("");
  const [courses, set_courses] = useState([]);
  const [unit, set_unit] = useState("");
  const [description, set_description] = useState("");
  const [show_courses, set_show_courses] = useState(false);

  // Sub section
  const [show_sub_section, set_show_sub_section] = useState(false);
  const [sections_list, set_sections_list] = useState([]);
  const [section_id, set_section_id] = useState("");

  const [errors, set_errors] = useState([]);

  // Button loader
  const [button_loader, set_button_loader] = useState(false);

  const [page_loader_2, set_page_loader_2] = useState(true);

  const ADD_API = async (type) => {
    set_button_loader(true);
    if (type == 'course') {
      const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", USER_DATA.token);
      FORM_DATA.append("title", title);
      FORM_DATA.append("description", editorRef.current.getContent());
      FORM_DATA.append("units", unit);
      FORM_DATA.append("programme_id", programme_id);
      FORM_DATA.append("course_status", 1);
      const API_RESPONSE = await ADD_SECTION(FORM_DATA);
      if (API_RESPONSE?.data.status) {
        set_open_section(API_RESPONSE?.data?.id)
        set_add_section_form(false)
        set_errors(API_RESPONSE?.data.errors);
        set_button_loader(false);
        set_page_loader(true);
        set_add_section_button(true)
      } else {
        set_errors(API_RESPONSE?.data.errors);
        set_button_loader(false);
      }
    } else {
      const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
      const FORM_DATA = new FormData();
      FORM_DATA.append("token", USER_DATA.token);
      FORM_DATA.append("title", title);
      FORM_DATA.append("description", editorRef.current.getContent());
      FORM_DATA.append("units", unit);
      FORM_DATA.append("programme_id", programme_id);
      FORM_DATA.append("course_status", 0);
      const API_RESPONSE = await ADD_SECTION(FORM_DATA);
      if (API_RESPONSE?.data.status) {
        set_add_section_form(false)
        set_add_section_button(true)
        set_open_section(API_RESPONSE?.data?.id)
        set_errors(API_RESPONSE?.data.errors);
        set_button_loader(false);
        set_page_loader(true);

      } else {
        set_errors(API_RESPONSE?.data.errors);
        set_button_loader(false);
      }
    }
  };
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("programme_id", programme_id);
    FORM_DATA.append("parent", section_id);
    const API_RESPONSE = await SUBSECTIONS_LIST(FORM_DATA);
    if (API_RESPONSE?.data.status) {
      set_sub_section(API_RESPONSE?.data.sub_sections);
      set_page_loader_2(false);
    } else {
    }
  };
  const UPDATE_API = async () => {
    set_button_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", section_id);
    FORM_DATA.append("title", title);
    FORM_DATA.append("description", editorRef.current.getContent());
    FORM_DATA.append("units", unit);
    FORM_DATA.append("courses", courses);
    FORM_DATA.append("type", "course");
    FORM_DATA.append("programme_id", programme_id);
    FORM_DATA.append("course_status", 1);
    FORM_DATA.append("sub_section", 0);
    const API_RESPONSE = await UPDATE_SECTION3(FORM_DATA);
    if (API_RESPONSE?.data.status) {
      set_open_section(API_RESPONSE?.data.id);
      set_add_section_form(false);
      set_add_section_button(true);
      set_page_loader(true);
      notification.open({
        message: "Success!!",
        description: "Programme section Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
    } else {
      set_errors(API_RESPONSE?.data.errors);
      set_button_loader(false);
    }
  };
  useEffect(() => {
    LIST_API();
    set_sub_page_loader(false);
  }, [sub_page_loader]);

  const delete_section = () => {
    set_add_section_form(false);
    set_add_section_button(true);
    set_page_loader(true);
  };
  return (
    <>
      {page_loader_2 ? (
        <SisLoader />
      ) : (
        <>
          <div className="sis-section">
            <div className="sis-section-head">
              <h4> {title ? title : "Add Programme section"}</h4>
              <div>
                <b onClick={delete_section}>
                  <DeleteOutlined />
                </b>
                {show_form ? (
                  <b onClick={() => set_show_form(false)}>
                    <DownOutlined />{" "}
                  </b>
                ) : (
                  <b onClick={() => set_show_form(true)}>
                    {" "}
                    <UpOutlined />{" "}
                  </b>
                )}
              </div>
            </div>

            {show_form && (
              <>
                <div className="sis-section-container">
                  <div className="input-box">
                    <label htmlFor="name">
                      Section Title<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      value={title}
                      placeholder="Title"
                      id="title"
                      onChange={(e) => set_title(e.target.value)}
                    />
                    {errors?.title && (
                      <>
                        <span style={{ color: "red" }}>{errors?.title[0]}</span>
                      </>
                    )}
                  </div>
                  <div className="input-box">
                    <label htmlFor="name">
                      Section Description
                    </label>
                    <Editor
                      apiKey={TINY_KEY}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={description}
                      init={{
                        height: 200,
                        menubar: false,
                        plugins:
                          "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools",
                        toolbar:
                          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                    {errors?.description && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.description[0]}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="input-box">
                    <label htmlFor="Units">
                      Section Units<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      value={unit}
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Programme Units"
                      id="Units"
                      onChange={(e) => set_unit(e.target.value)}
                    />
                    {errors?.units && (
                      <>
                        <span style={{ color: "red" }}>{errors?.units[0]}</span>
                      </>
                    )}
                  </div>
                  <div className="input-box">
                    {button_loader ? (
                      <>
                        <span
                          style={{
                            color: "orange",
                            cursor: "not-allowed",
                            fontWeight: "bold",
                          }}
                        >
                          {show_courses ? <span className='radio-button'></span> : <span className='radio-button'></span>} Course
                        </span>{" "}
                        or{" "}
                        <span
                          style={{
                            color: "orange",
                            cursor: "not-allowed",
                            fontWeight: "bold",
                          }}
                        >
                          {show_courses ? <span className='radio-button'></span> : <span className='radio-button'></span>} Sub Section
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            color: "orange",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={() => ADD_API("course")}
                        >
                          {show_courses ? <span className='radio-button'></span> : <span className='radio-button'></span>} Course
                        </span>{" "}
                        or{" "}
                        <span
                          style={{
                            color: "orange",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={() => ADD_API("sub_section")}
                        >
                          {show_courses ? <span className='radio-button'></span> : <span className='radio-button'></span>} Sub Section
                        </span>
                      </>
                    )}
                  </div>
                  {show_courses && (
                    <>
                      <CourseContainer
                        set_courses={set_courses}
                        courses={courses}
                      />
                      {errors?.courses && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.courses[0]}
                          </span>
                        </>
                      )}
                      <div className="input-box" style={{ textAlign: "right" }}>
                        {button_loader ? (
                          <>
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    fontSize: "12px",
                                    color: "#fff",
                                    marginRight: "5px",
                                  }}
                                />
                              }
                            />
                            <Button  className='lusisbtn' style={{width:"156px"}} type="primary" disabled>
                              Save Section
                            </Button>
                          </>
                        ) : (
                          <Button  className='lusisbtn' style={{width:"156px"}} type="primary" onClick={UPDATE_API}>
                            Save Section
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                  {!show_courses && (
                    <>
                      {sub_section.length > 0 && (
                        <>
                          {sub_section.map((item, index) => (
                            <>
                              {sub_page_loader ? (
                                <SisLoader />
                              ) : (
                                <>
                                  <EditSubSection
                                    section_item={item}
                                    sub_section_id={item.id}
                                    set_page_loader ={set_page_loader}
                                    set_open_section={set_open_section}
                                    section_id={section_id}
                                    set_open_sub_section={open_sub_section}
                                    programme_id={programme_id}
                                    set_sub_page_loader={set_sub_page_loader}
                                    set_show_sub_section={set_show_sub_section}
                                  />
                                </>
                              )}
                            </>
                          ))}
                        </>
                      )}
                    </>
                  )}
                  {show_sub_section && (
                    <>
                      <AddSubSection
                        section_id={section_id}
                        set_open_sub_section={open_sub_section}
                        programme_id={programme_id}
                        set_page_loader ={set_page_loader}
                        set_open_section={set_open_section}
                        set_sub_page_loader={set_sub_page_loader}
                        set_show_sub_section={set_show_sub_section}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AddSection;
