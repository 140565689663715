import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Button,
  Checkbox,
  Input,
  Radio,
  Select,
  Spin,
  Table,
  notification,
  DatePicker,
  Space,
  Divider,
  List,
  Typography,
} from "antd";
import { InputNumber } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  LoadingOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  SIS_STORAGE,
  CALENDARS_LIST,
  PROGRAMS_LIST,
  STUDENTS_ENROLLMENTS_LIST,
  STUDENTS_ENROLLMENTS_VIEW,
  EDIT_ENROLLMENT,
} from "../../apis/apis";

import SisLoader from "../../widgets/loader";
import Item from "antd/es/list/Item";
import { useNavigate, useParams } from "react-router-dom";
import countryList from "react-select-country-list";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import "./style.css";

import StudentImage from "../../images/user.png";

dayjs.extend(customParseFormat);

const AddEnrollment = () => {
  const dateFormat = 'MM-DD-YYYY';
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [entering_year, set_entering_year] = useState("");
  const [matriculation_semester_id, set_matriculation_semester_id] = useState("");
  const [confer_date, set_confer_date] = useState("");
  const [status_id, set_status_id] = useState("");
  const [entering_class, set_entering_class] = useState("");
  const [programme_list, set_programme_list] = useState([]);
  const [student_list, set_student_list] = useState([]);
  const [student_info, set_student_info] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [table_list, set_table_list] = useState([]);
  const [entering_year_list, set_entering_year_list] = useState([]);
  const [page_loader, set_page_loader] = useState(true);
  const [enroll_id, set_enroll_id] = useState("");
  const [url_image, set_url_image] = useState("");

  // Form Data
  const [image, set_image] = useState("");
  const [first_name, set_first_name] = useState("");
  const [middle_name, set_middle_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [student_id, set_student_id] = useState("");
  const [application_no, set_application_no] = useState("");
  const [programme_intended, set_programme_intended] = useState("");
  const [programme_status, set_programme_status] = useState("");
  const [student_status, set_student_status] = useState("");
  const [matriculation_semester, set_matriculation_semester] = useState("");
  const [matriculation_date, set_matriculation_date] = useState("");
  const [entering_classification, set_entering_classification] = useState("");
  const [student_type, set_student_type] = useState("");
  const [programme_id, set_programme_id] = useState("");
  const [student_status_check, set_student_status_check] = useState();
  const [program_concentration, set_program_concentration] = useState([]);
  const [selected_program_concentration, set_selected_program_concentration] = useState('');
  const [sem_list, set_sem_list] = useState([]);
  const [calender_index, set_calender_index] = useState(0)

  var entry_year_selected = '';
  // VIEW API Sets Initial Data

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("image", image);
    const VIEW_API_RESPONSE = await STUDENTS_ENROLLMENTS_VIEW(FORM_DATA);
    if (VIEW_API_RESPONSE?.data?.status) {
      if (VIEW_API_RESPONSE?.data?.enrollments) {
        set_application_no(VIEW_API_RESPONSE?.data?.enrollments?.application_no);
        set_image(VIEW_API_RESPONSE?.data?.enrollments?.image);
        set_enroll_id(VIEW_API_RESPONSE?.data?.enrollments?.id);
        set_student_id(VIEW_API_RESPONSE?.data?.enrollments?.student_id);
        set_first_name(VIEW_API_RESPONSE?.data?.enrollments?.first_name);
        set_middle_name(VIEW_API_RESPONSE?.data?.enrollments?.middle_name);
        set_last_name(VIEW_API_RESPONSE?.data?.enrollments?.last_name);
        set_program_concentration(VIEW_API_RESPONSE?.data?.program_concentration);
        set_programme_intended(
          VIEW_API_RESPONSE?.data?.enrollments?.programme_intended
        );
        set_student_status(VIEW_API_RESPONSE?.data?.enrollments?.student_status);
        set_student_status_check(VIEW_API_RESPONSE?.data?.enrollments?.student_status);
      }
      if (VIEW_API_RESPONSE?.data?.programme) {
        set_selected_program_concentration(VIEW_API_RESPONSE?.data?.programme?.program_concentration)
        set_student_type(VIEW_API_RESPONSE?.data?.programme?.student_type)
        set_matriculation_semester(
          parseInt(VIEW_API_RESPONSE?.data?.programme?.matriculation_semester)
        );
        set_matriculation_date(
          VIEW_API_RESPONSE?.data?.programme?.matriculation_date
        );
        set_confer_date(VIEW_API_RESPONSE?.data?.programme?.confer_date != 'null' && VIEW_API_RESPONSE?.data?.programme?.confer_date && VIEW_API_RESPONSE?.data?.programme?.confer_date);
        set_programme_status(
          VIEW_API_RESPONSE?.data?.programme?.programme_status
        );
        set_entering_classification(
          VIEW_API_RESPONSE?.data?.programme?.entering_classification
        );
        set_entering_year(VIEW_API_RESPONSE?.data?.programme?.entering_year);
        set_sem_list(VIEW_API_RESPONSE?.data?.selected_semester_list)
        entry_year_selected = VIEW_API_RESPONSE?.data?.programme?.entering_year;
      }
      set_page_loader(false);
    }
  };


  const STUDENTS_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("image", image);
    FORM_DATA.append("first_name", first_name);
    FORM_DATA.append("middle_name", middle_name);
    FORM_DATA.append("last_name", last_name);
    FORM_DATA.append("student_id", status_id);
    FORM_DATA.append("student_status", student_status);
    FORM_DATA.append("programme_intended", programme_intended);
    const API_RESPONSE = await STUDENTS_ENROLLMENTS_LIST(FORM_DATA);
    // console.log(image)
    if (API_RESPONSE?.data?.status) {
      set_student_list(API_RESPONSE?.data?.student);
      VIEW_API(API_RESPONSE?.data?.student);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_programme_list(API_RESPONSE?.data?.programmes);
    } else {
      set_loader(false);
    }
  };



  // calender list

  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_entering_year_list(API_RESPONSE?.data?.calendars);
      //set_sem_list(API_RESPONSE?.data?.calendars[calender_index]?.semesters)

      //  set_matriculation_semester(API_RESPONSE.data.calendars[calender_index].semesters[0])
      set_loader(false);
    } else {
      set_loader(false);
    }
  }

  // useEffect(() => {
  //   PROGRAMS_LIST_API();
  //   // STUDENTS_API();
  //   LIST_API();

  // }, [loader]);
  useEffect(() => {
    PROGRAMS_LIST_API();
    STUDENTS_API();
    LIST_API();
    //CALENDARS_LIST();
    // VIEW_API();
  }, [loader]);
  const handleImageChange = (e) => {
    const files = e.target.files;
    if (
      (files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png") &&
      files[0].size < 2000001
    ) {
      set_url_image(files[0]);
    } else {
      notification.open({
        message: "Not Allowed",
        description:
          "Please check image File format must be .jpg or .png and Max. file size 2 Mb",
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
    }
  };
  const handleRemoveImage = (e) => {
    set_url_image("");
  };

  //for disable button
  useEffect(() => {
    if (
      programme_id &&
      student_id &&
      entering_year &&
      matriculation_semester_id &&
      matriculation_date &&
      status_id &&
      entering_class
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [
    programme_id,
    student_id,
    entering_year,
    matriculation_semester_id,
    matriculation_date,
    confer_date,
    status_id,
    entering_class,
  ]);

  const calender_change_function = (value) => {
    set_matriculation_semester('');
    const index = entering_year_list.findIndex(item => item.id === value)
    set_entering_year(value);
    console.log("enter year value dynamic", value);
    set_calender_index(index);
    console.log(index);
    set_sem_list(entering_year_list[index].semesters);
    // entering_year_list[index].semesters.map((item)=>{
    //   console.log(item.title);
    // })
  }

  const ADD_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("image", image);
    FORM_DATA.append("first_name", first_name);
    FORM_DATA.append("middle_name", middle_name);
    FORM_DATA.append("last_name", last_name);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("student_status", student_status);
    FORM_DATA.append("programme_intended", programme_intended);
    FORM_DATA.append("entering_year", entering_year);
    FORM_DATA.append("matriculation_semester", matriculation_semester);
    FORM_DATA.append("matriculation_date", matriculation_date);
    FORM_DATA.append("confer_date", confer_date);
    FORM_DATA.append("programme_status", (programme_status == "null" || programme_status == null) ? " " : programme_status);
    FORM_DATA.append("program_concentration", selected_program_concentration !== 'null' ? selected_program_concentration : '');

    FORM_DATA.append("programme_id", programme_id);
    FORM_DATA.append("student_type", (student_type == "null" || student_type == null) ? " " : student_type);
    FORM_DATA.append("entering_classification", (entering_classification == "null" || entering_classification == null) ? " " : entering_classification );
    const API_RESPONSE = await EDIT_ENROLLMENT(FORM_DATA);
    console.log(API_RESPONSE);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student Enrollment Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/enrollments-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };

  return (
    <>
    {page_loader ? (
      <SisLoader />
    ) : (
      <>
        {errors?.try && (
          <>
            <span style={{ color: "red" }}>{errors?.try[0]}</span>
          </>
        )}
        {errors?.catch && (
          <>
            <span style={{ color: "red" }}>{errors?.catch[0]}</span>
          </>
        )}
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3 style={{marginLeft:"10px"}}>Register Student in Program</h3>
        </div>

        <div className="theme-content-right-head"></div>
      </div>
      <div className="common-form">
       
            <div className="col-8" style={{ width: "100%" }}>
              {/* Basic Information */}
              <>
                <div className="theme-content-left-head">
                  <h3>Basic Information</h3>
                </div>
                <div className="basic-information">
                  {/* Student Image */}
                  <div className="input-box" style={{ position: "relative" }}>
                    <label htmlFor="address">Image</label>
                    <br></br>
                    {/* <label className='upload-box'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={handleImageChange} /> <CloudUploadOutlined /> Upload Image</label> */}
                    {url_image ? (
                      <>
                        <div
                          className="image-box"
                          style={{
                            backgroundImage:
                              "url(" + URL.createObjectURL(url_image) + ")",
                          }}
                        ></div>
                        <button
                          className="image-remove"
                          onClick={() => handleRemoveImage()}
                        >
                          <CloseOutlined />
                        </button>
                      </>
                    ) : (
                      <>
                        {image && (
                          <>
                            <div
                              className="image-box"
                              style={{
                                backgroundImage:
                                  "url(" +
                                  SIS_STORAGE +
                                  "/enrollments/" +
                                  image +
                                  ")",
                              }}
                            ></div>
                          </>
                        )}
                      </>
                    )}

                    {errors?.image && (
                      <>
                        <span style={{ color: "red" }}>{errors?.image[0]}</span>
                      </>
                    )}
                  </div>

                  {/* First Name */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      First Name<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Input value={first_name} disabled />

                    {/* <Select
                                    placeholder="Select Student"
                                    style={{ width: '100%' }}
                                    onChange={(value) => { set_student_first_name(value); set_student_info(student_list.filter(obj => obj.id === value)) }}
                                    value={student_id}
                                    disabled
                                >
                                    {student_list.map((item) => {
                                        if (item.status === 1 && item.soft_delete === 0) {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {`${item.first_name}`}
                                                </Select.Option>
                                            );
                                        }
                                    })}
                                </Select> */}

                    {errors?.first_name && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.first_name[0]}
                        </span>
                      </>
                    )}
                  </div>

                  {/* Middle Name */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Middle Name
                    </label>
                    <br></br>
                    <Input value={middle_name === 'null' ? '' : middle_name} disabled />
                    {/* <Select
                                    placeholder="Select Student"
                                    style={{ width: '100%' }}
                                    onChange={(value) => { set_student_id(value); set_student_info(student_list.filter(obj => obj.id === value)) }}
                                    value={student_id}
                                    disabled

                                >
                                    {student_list.map((item) => {
                                        if (item.status === 1 && item.soft_delete === 0) {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {` ${item.middle_name}`}
                                                </Select.Option>
                                            );
                                        }
                                    })}
                                </Select> */}

                    {errors?.middle_name && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.middle_name[0]}
                        </span>
                      </>
                    )}
                  </div>
                  {/* Last Name */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Last Name<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Input
                      // onChange={(value) => { set_last_name(value); set_student_info(last_name.filter(obj => obj.id === value)) }}
                      value={last_name}
                      disabled
                    />
                    {/* <Select
                                    placeholder="Select Student"
                                    style={{ width: '100%' }}
                                    onChange={(value) => { set_last_name(value); set_student_info(last_name.filter(obj => obj.id === value)) }}
                                    value={last_name}
                                    disabled

                                >
                                    {student_list.map((item) => {
                                        if (item.status === 1 && item.soft_delete === 0) {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {`${item.last_name}`}
                                                </Select.Option>
                                            );
                                        }
                                    })}
                                </Select> */}

                    {errors?.last_name && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.last_name[0]}
                        </span>
                      </>
                    )}
                  </div>
                  {/* Student ID */}
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Student ID<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Input
                      // onChange={(value) => { set_last_name(value); set_student_info(last_name.filter(obj => obj.id === value)) }}
                      value={student_id}
                      disabled
                    />
                    {/* <Select
                      placeholder="Select Student"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        set_student_id(value);
                        set_student_info(
                          student_list.filter((obj) => obj.id === value)
                        );
                      }}
                      value={student_id}
                      disabled
                    /> */}

                    {errors?.student_id && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.student_id[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </>
              <br />
              <hr />
              <br />

              {/* Program / Degree intended */}
              <>
                <div className="row">
                  <div className="input-box col-6">
                    <label>
                      Program/Degree intended<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Select
                      placeholder="--Select Programme--"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        set_programme_intended(value);
                      }}
                      value={programme_intended}
                      disabled
                    >
                      {programme_list.map((item) => {
                        if (item.status === 1 && item.soft_delete === 0) {
                          return (
                            <>
                              <Select.Option value={item.id}>
                                {item.title}
                              </Select.Option>
                            </>
                          );
                        }
                      })}
                    </Select>
                    {errors?.programme_id && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.programme_id[0]}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="input-box col-6">
                    <label>
                      Program Concentration<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Select
                      placeholder="--Select Programme--"
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        set_selected_program_concentration(value);
                      }}
                      disabled={program_concentration.length === 0}
                      value={selected_program_concentration === 0 ? "" : selected_program_concentration}
                    >
                      {program_concentration && program_concentration?.map((item) => {
                        if (item.status === 1 && item.soft_delete === 0) {
                          return (
                            <>
                              <Select.Option value={item.id}>
                                {item.title}
                              </Select.Option>
                            </>
                          );
                        }
                      })}
                    </Select>
                    {errors?.program_concentration && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.program_concentration[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

              </>
              <br />
              <hr />
              <br />

              {/* Program Registration */}
              <>
                <div className="theme-content-left-head">
                  <h3>Program Registration</h3>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="input-box">
                      <label>
                        Student Type<i style={{ color: "red" }}>*</i>
                      </label>
                      <br></br>
                      <Select
                        placeholder="-- Student Type--"
                        style={{ width: "200px", display: "block" }}
                        onChange={(value) => {
                          set_student_type(value);
                        }}
                        value={student_type}

                      >
                        {['New', 'Transferred', 'Returning', 'Alumni'].map((item) => {
                          return (
                            <>
                              <Select.Option value={item}>
                                {item}
                              </Select.Option>
                            </>
                          );
                        })}
                      </Select>
                      {errors?.student_type && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.student_type[0]}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>
                        Academic Year<i style={{ color: "red" }}>*</i>
                      </label>
                      <br />
                      <Select
                        placeholder="--Select Academic Year--"
                        style={{ width: "100%" }}
                        onChange={calender_change_function}
                        value={entering_year ? parseInt(entering_year) : entering_year}
                        options={entering_year_list.map((item) => ({
                          value: item.id,
                          calender_id: item.id,
                          label: item.title,
                        }))}

                      />

                      {/* <DatePicker format={dateFormat}
                                    onChange={(date, dateString) => set_entering_year(dateString)}
                                    // value={entering_year}
                                    style={{ width: '100%' }}
                                    placeholder="Select Matriculation Date"
                                    required /> */}
                      {/* <Input onChange={(e) => set_entering_year(e.target.value)} type='number' placeholder="Enter Entering Year" required /> */}
                      {errors?.entering_year && (
                        <>
                          <span style={{ color: "red" }}>
                            The academic year field is required.
                            {/* {errors?.academic_year[0]} */}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  {/* Matriculation Semester */}
                  <div className="col-3">
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>
                        Matriculation Semester<i style={{ color: "red" }}>*</i>
                      </label>
                      <br />

                      <Select
                        placeholder="--Select Semester--"
                        style={{ width: "100%" }}
                        disabled={Boolean ? !entering_year : entering_year} // Disable until a academic year is selected
                        onChange={(value) => {
                          set_matriculation_semester(value);
                        }}
                        value={matriculation_semester}
                        options={sem_list?.map((item) => ({
                          value: item.id,
                          label: item.title,
                        }))}
                      />

                      {errors?.matriculation_semester && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.matriculation_semester[0]}
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Matriculation Date */}
                  <div className="col-3">
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>
                        Matriculation Date<i style={{ color: "red" }}>*</i>
                      </label>
                      <br />

                      <DatePicker format={dateFormat}
                        value={matriculation_date && dayjs(matriculation_date)}
                        onChange={(date, dateString) =>
                          set_matriculation_date(dateString)
                        }
                        style={{ width: "100%" }}
                      />

                      {errors?.matriculation_date && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.matriculation_date[0]}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Confer Date */}
                  <div className="col-3">
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>
                        Confer Date
                      </label>
                      <br />

                      <DatePicker format={dateFormat}
                        value={confer_date && dayjs(confer_date)}
                        onChange={(date, dateString) =>
                          set_confer_date(dateString)
                        }
                        style={{ width: "100%" }}
                      />

                      {/* <DatePicker format={dateFormat}
                                    value={dayjs(confer_date)}
                                    onChange={(date, dateString) => set_confer_date(date, dateString, 'start_date')}
                                    dateFormat='yy-mm-dd'
                                    style={{ width: "100%" }} /> */}
                      {errors?.confer_date && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.confer_date[0]}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  {/* Program Status */}
                  <div className="col-3">
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>
                      Academic Standing <i style={{ color: "red" }}>*</i>
                      </label>
                      <br />
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Program Status"
                        onChange={(value) => {
                          set_programme_status(value);
                        }}
                        value={programme_status}
                        required
                      >
                        {/* <Select.Option value="N/A">N/A</Select.Option>
                                    <Select.Option value="Completed">Completed</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>
                                    <Select.Option value="active">Active</Select.Option>
                                    <Select.Option value="inactive">Inactive</Select.Option> */}

                        <Select.Option value="Active">Active</Select.Option>
                        <Select.Option value="Completed">Completed</Select.Option>
                        <Select.Option value="N/A">N/A</Select.Option>
                        <Select.Option value="Inactive">Inactive</Select.Option>
                        <Select.Option value="Leave of Absent">
                          Leave of Absent
                        </Select.Option>
                        <Select.Option value="Reduced Load">
                          Reduced Load
                        </Select.Option>
                        <Select.Option value="Transfer Out">
                          Transfer Out
                        </Select.Option>
                        <Select.Option value="Withdraw">Withdraw</Select.Option>
                        <Select.Option value="Good Standing">Good Standing</Select.Option>
                        <Select.Option value="Academic Probation">Academic Probation</Select.Option>
                        <Select.Option value="Academic Suspension">Academic Suspension</Select.Option>
                        <Select.Option value="Dismissed">Dismissed</Select.Option>
                        <Select.Option value="Conditional Admission">Conditional Admission</Select.Option>
                        <Select.Option value="Readmission">Readmission</Select.Option>
                        <Select.Option value="Graduated">Graduated</Select.Option>
                        <Select.Option value="In Progress">In Progress</Select.Option>
                        <Select.Option value="Incomplete">Incomplete</Select.Option>
                        <Select.Option value="Other">Other</Select.Option>
                      </Select>
                      {errors?.programme_status && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.programme_status[0]}
                           {/* The program status field is required. */}
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Entering Classification */}
                  <div className="col-3">
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>
                        Entering Classification<i style={{ color: "red" }}>*</i>
                      </label>
                      <br />
                      <Select
                        onChange={(value) => {
                          set_entering_classification(value);
                        }}
                        value={entering_classification}
                        style={{ width: "100%" }}
                        placeholder="Select Entering Classifications"
                        required
                      >
                        <Select.Option value="undergraduate">
                          Undergraduate
                        </Select.Option>
                        <Select.Option value="graduate">Graduate</Select.Option>
                      </Select>
                      {errors?.entering_classification && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.entering_classification[0]}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
              <br />
              <hr />
              <br />

              {/* Student status */}
              <>
                <div style={{ width: "100%" }}>
                  <div className="input-box" style={{ width: "200px" }}>
                    <label>
                      Student status<i style={{ color: "red" }}>*</i>
                    </label>
                    <br></br>
                    <Select
                      placeholder="Select Status"
                      style={{ width: "100%" }}
                      value={parseInt(student_status)}
                      onChange={(value) => set_student_status(value)}
                    >
                      <Select.Option value={2}>
                        Enrolled
                      </Select.Option>
                      <Select.Option value={1}>
                        Ready for Programme registration
                      </Select.Option>
                    </Select>
                    {errors?.student_status && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.student_status[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </>
              <br />
              <br />
              <div className="input-box">
                {loader ? (
                  <>
                    <Button type="primary" className="lusisbtn" style={{width:"156px"}}>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Enrol Student
                    </Button>
                  </>
                ) : (
                  <>
                    <Button className="lusisbtn" style={{width:"156px"}} type="primary" onClick={ADD_API}>
                      Enrol Student
                    </Button>
                  </>
                )}
              </div>
            </div>
      </div>
    </div>
    </>
    )}
    </>
  );
};

export default AddEnrollment;
